import styles from './Init.module.scss';
import './App.css';

function App() {
  return (
    <div className={styles.container}>
      <div className={styles.name}>znava</div>
    </div>
  );
}

export default App;
